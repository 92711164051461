import { DefaultTheme } from 'styled-components'

export function headingFontSize({
  size,
  theme,
}: {
  size: string | number
  theme: DefaultTheme
}): string {
  const sizeIndex = typeof size === 'number' ? size : Number(size)
  const sizeDetails = theme.fonts.heading.sizes[sizeIndex]
  if (!sizeDetails) {
    console.error(`Heading size "${size}" not found`)
    return '16px'
  }
  return `${sizeDetails.fontSize}px`
}
